<template>
  <div class="event-card">
    <div class="card h-100">
      <!-- Card Header -->
      <div
        :class="['card-header', card.focus ? 'bg-custom-focus' : 'bg-custom', 'd-flex', 'justify-content-between', 'align-items-center']">
        <span class="invisible"><i class="fas fa-pencil-alt"></i></span>
        <b class="card-header-title d-block text-center ml-5 w-100">{{ card.focus ? 'Currently on focus' :
          'Target' }}</b>
        <a href="#!" class="edit-button" @click="$emit('editCard', card)">
          <i class="fas fa-pencil-alt"></i>
        </a>
      </div>

      <!-- Card Body -->
      <div class="card-body">
        <b>
          <h3 class="card-title">{{ card.title || "Loading..." }}</h3>
        </b>
        <small class="card-title">Current weekly score: <b>0</b> (+0% last week)</small>
        <div v-if="!card.text" class="spinner-container">
          <Spinner />
        </div>
        <p class="card-text">{{ card.text }}</p>
        <!-- Context -->
        <div class="btn-group mb-2">
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
            data-bs-auto-close="false" aria-expanded="false">
            Select context
          </button>
          <ul class="dropdown-menu">
            <li v-for="(context, index) in card.context" :key="index">
              <a class="dropdown-item" :class="{ 'active': isSelected(context) }" @click="addContext(context)"
                href="#!">{{
                  `${context.name} (${context.value} P)` }}
              </a>
            </li>
          </ul>
        </div>
        <!-- Display selected contexts for debugging or other purposes -->
        <div>
          <ul class="list-group list-group-flush">
            <li v-for="context in selectedContexts" :key="context.name" class="list-group-item">{{ `${context.name}
              (${context.value} P)` }}</li>
            <li v-if="getPoints() !== 0" class="list-group-item"><b>{{ getPoints() }} Points</b></li>
          </ul>
        </div>
        <hr/>
        <p class="text-primary" v-if="showMsg">{{ msg }}</p>
        <a href="#!" :class="getButtonClass()" data-mdb-ripple-color="dark" @click="bookPoints(getPoints())">Log Event</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Spinner from '@/components/Spinner.vue'
// import { mapGetters, mapActions } from 'vuex';

export interface IEventCard {
  id: number,
  title: string,
  text: string,
  context: IContext[],
  focus: boolean,
  maxEffectPerDay?: number, // Dont forget to ^-1 to normalise -1 and 1
  maxEffectPerEvent?: number, // Dont forget to ^-1 to normalise -1 and 1
  goodEvent?: boolean
};

export interface IContext {
  name: string,
  value: number,
}

export default defineComponent({
  name: 'EventCard',
  props: {
    card: {
      type: Object as PropType<IEventCard>,
      required: true
    }
  },
  // computed: {
  //   ...mapGetters(['userName', 'userPoints'])
  // },
  components: {
    Spinner,
  },
  data() {
    return {
      selectedContexts: [] as IContext[],
      showMsg: false as boolean,
      msg: '' as string
    };
  },
  methods: {
    // ...mapActions(['bookPoints']),
    // addPoints() {
    //   this.bookPoints(10);
    // },
    bookPoints(points: number) {
      if (points < 0 || points > 0) {
        this.selectedContexts = [];
        this.msg = `Booked ${points} Points!`;
        this.showMsg = true;
        setTimeout(() => {
          this.showMsg = false;
        }, 3000);
      }

    },
    addContext(context: IContext) {
      if (!this.selectedContexts.includes(context)) {
        this.selectedContexts.push(context);
      } else {
        this.removeContext(context);
      }
    },
    removeContext(context: IContext) {
      const index = this.selectedContexts.indexOf(context);
      if (index !== -1) {
        this.selectedContexts.splice(index, 1);
      }
    },
    isSelected(context: IContext): boolean {
      return this.selectedContexts.includes(context);
    },
    getPoints(): number {
      let points = 0;
      this.selectedContexts.forEach(context => {
        points += context.value
      })

      if (this.card.maxEffectPerEvent && (points ^ -1) > this.card.maxEffectPerEvent) {
        points = points > 0 ? this.card.maxEffectPerEvent : this.card.maxEffectPerEvent * -1;
      }

      if (this.card.maxEffectPerDay && (points ^ -1) > this.card.maxEffectPerDay) {
        points = points > 0 ? this.card.maxEffectPerDay : this.card.maxEffectPerDay * -1;
      }

      return points;
    },
    getButtonClass(): string {
      if (this.card.goodEvent) return 'btn btn-outline-success';
      return 'btn btn-outline-danger';
    }
  }
});
</script>

<style lang="scss" scoped>

.event-card {
  margin: 0.5em;
  padding: 0.5em;
  min-width: 10em;
}

.card {
  min-height: 10em;
}

.card-text {
  min-height: 1em;
  margin-bottom: 1em;
}

.card-img-top {
  padding: 0.5em;
}

.active * {
  font-weight: bold;
}

i {
  color: #ff8c8c !important;
}

.bg-custom-focus {
  background-color: $card-header-color;
}

.bg-custom {
  background-color: $accent-color;

  .card-header-title {
    color: black !important;
  }

  // i {
  //   color: black !important;
  // }
}

.card-header {
  position: relative;
  padding-right: 50px; /* Adjust this value to provide space for the edit button */
}

.edit-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Adjust this value to control the width of the edit button */
  border-left: 0.01em dashed black;
  color: #fff;
  text-decoration: none;
  background-color: inherit;
  transition: opacity 0.2s ease, border-left-color 0.2s ease; /* Add transition for opacity and border-left-color */

}

.edit-button i {
  font-size: 1.2em;
}

.edit-button:hover {
  opacity: 0.7;
}
</style>