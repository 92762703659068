import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ab49518"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "launchButton",
  class: "",
  "data-bs-toggle": "modal",
  "data-bs-target": "#cardModal"
}
const _hoisted_2 = {
  class: "modal fade",
  id: "cardModal",
  tabindex: "-1",
  "aria-labelledby": "cardModalLabel",
  "aria-hidden": "false"
}
const _hoisted_3 = { class: "modal-dialog" }
const _hoisted_4 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventCardModal = _resolveComponent("EventCardModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_EventCardModal, {
            card: _ctx.localCard,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, null, 8, ["card"])
        ])
      ])
    ])
  ], 64))
}