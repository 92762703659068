<template>
  <!-- Modal -->
  <div ref="launchButton" class="" data-bs-toggle="modal" data-bs-target="#cardModal"/>
  <div class="modal fade" id="cardModal" tabindex="-1" aria-labelledby="cardModalLabel" aria-hidden="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <EventCardModal :card="localCard" @close="$emit('close')"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, onMounted, watch } from 'vue';
// import { Modal } from 'bootstrap';
import { IEventCard } from './EventCard.vue';
import EventCardModal from './modals/EventCardModal.vue';

export default defineComponent({
  name: 'CardModal',
  components: {
    EventCardModal
  },
  props: {
    modalType: {
      type: String as PropType<'edit' | 'add'>,
      required: true
    },
    card: {
      type: Object as PropType<IEventCard>,
      required: false,
      default: () => ({})
    }
  },
  setup(props) {
    const localCard = ref<IEventCard | {}>(props.modalType === 'add' ? {} : { ...props.card });

    watch(() => props.card, (newCard) => {
      if (props.modalType === 'edit') {
        localCard.value = { ...newCard };
      }
    });

    const launchButton = ref<HTMLButtonElement | null>(null);

    onMounted(() => {
      if (launchButton.value) {
        launchButton.value.click();
      }
    });

    // onMounted(() => {
    //   const modalElement = document.getElementById('cardModal');
    //   if (modalElement) {
    //     const modal = new Modal(modalElement);
    //     modal.show();
    //   } else {
    //     console.error('Modal element not found');
    //   }
    // });

    return {
      localCard, launchButton
    };
  }
});
</script>

<style scoped>
/* .modal {
  margin-top: 2em !important;
} */
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-content {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>
