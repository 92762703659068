//import { createStore } from 'vuex'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// import data from './data'
// import user from './user'

export default new Vuex.Store({
  modules: {
    // data,
    // user
  },
  plugins: [createPersistedState()]
})