<template>
    <div class="modal-header">
        <h5 class="modal-title" id="cardModalLabel">{{ card.title ? 'Edit' : 'Add' }} Event Card</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            @click="$emit('close')"></button>
    </div>
    <div class="modal-body">
        <form @submit.prevent="handleSubmit">
            <!-- Title -->
            <div class="mb-3">
                <form class="form-floating">
                    <input type="text" class="form-control" id="floatingInputValue" placeholder="Event Title"
                        v-model="card.title"></input>
                    <label for="floatingInputValue">Event Title</label>
                </form>
            </div>
            <!-- Description -->
            <div class="mb-3">
                <form class="form-floating">
                    <input type="text" class="form-control" id="floatingInputValue" placeholder="Event Text"
                        v-model="card.text"></input>
                    <label for="floatingInputValue">Call for action</label>
                </form>
            </div>
            <!-- Max effect per day -->
            <div class="mb-3">
                <form class="form-floating">
                    <input type="number" class="form-control" id="floatingInputValue" placeholder="0"
                        v-model="card.maxEffectPerDay"></input>
                    <label for="floatingInputValue">Max points per day (0 for no limit)</label>
                </form>
            </div>
            <!-- Max effect per event -->
            <div class="mb-3">
                <form class="form-floating">
                    <input type="number" class="form-control" id="floatingInputValue" placeholder="0"
                        v-model="card.maxEffectPerEvent"></input>
                    <label for="floatingInputValue">Max points per event (0 for no limit)</label>
                </form>
            </div>
            <!-- Good event -->
            <div class="mb-3 form-check d-flex align-items-center">
              <input type="checkbox" class="form-check-input me-2" id="goodEvent" v-model="card.goodEvent">
              <label class="form-check-label" for="goodEvent">Good Event</label>
            </div>
            <!-- Focus -->
            <div class="mb-3 form-check d-flex align-items-center">
                <input type="checkbox" class="form-check-input me-2" id="focus" v-model="card.focus">
                <label class="form-check-label" for="focus">On Focus</label>
            </div>
            <hr/>
            <!-- Context -->
            <div class="mb-3">
                <label for="context" class="form-label">Context</label>
                <div v-for="(context, index) in card.context" :key="index" class="input-group mb-2">
                    <input type="text" class="form-control" v-model="context.name" placeholder="Name" required>
                    <input type="number" class="form-control" v-model="context.value" placeholder="Value" required>
                    <button type="button" class="btn btn-danger" @click="removeContext(index)">Remove</button>
                </div>
                <button type="button" class="btn btn-outline-success mt-1 w-100" @click="addContext">Add Context</button>
            </div>
            <!-- Buttons -->
            <hr/>
            <button type="button" class="btn btn-outline-primary m-1" data-bs-dismiss="modal" @click="$emit('close')">Save changes</button>
            <button type="button" class="btn btn-outline-secondary m-1" data-bs-dismiss="modal"
                @click="$emit('close')">Close</button>
        </form>
    </div>
</template>

<script>
export default {
    name: 'EventCardModal',
    props: {
        card: {
            type: Object,
            required: false,
            default: () => {
                return {
                    id: null,
                    name: null,
                    title: null,
                    context: [],
                    focus: false,
                    maxEffectPerDay: 0,
                    maxEffectPerEvent: 0,
                    goodEvent: false
                };
            }
        }
    },
    methods: {
        handleSubmit() {
            this.$emit('save', this.card);
        },
        addContext() {
            if (!this.card.context) this.card.context = [];
            this.card.context.push({ name: '', value: 0 });
        },
        removeContext(index) {
            this.card.context.splice(index, 1);
        }
    }
}
</script>

<style scoped>
</style>