<template>
  <!-- Header -->
  <NavigationBar />
  <!-- Container -->
  <div class="container justify-content-center align-items-center mx-auto vh-100">
    <router-view/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavigationBar from '@/components/header/NavigationBar.vue';

export default defineComponent({
  components: {
    NavigationBar,
  },
  /* Doesn'T work properly, check! */
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Be.Better',
      // all titles will be injected into this template
      titleTemplate: '%s | Be.Better'
  }
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container {
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 2vh;
}
</style>
