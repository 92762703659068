import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ed46221"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "row justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WelcomeBar = _resolveComponent("WelcomeBar")!
  const _component_EventCard = _resolveComponent("EventCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalComponent), {
            modalType: _ctx.modalArgument,
            card: _ctx.focusedCard,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
          }, null, 40, ["modalType", "card"]))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_WelcomeBar, {
      onEventModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showEventModal('add')))
    }),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "justify-content-center col-sm-12 col-md-6 col-lg-4 col-container",
          key: index
        }, [
          _createVNode(_component_EventCard, {
            card: card,
            onEditCard: _ctx.showEditModal
          }, null, 8, ["card", "onEditCard"])
        ]))
      }), 128))
    ])
  ]))
}