<template>
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</template>

<style lang="scss" scoped>
.spinner-border {
    margin-top: 1em;
}
</style>