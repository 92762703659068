<template>

  <div class="home">
    <div class="row" v-if="showModal">
      <Component :is="modalComponent" :modalType="modalArgument" :card="focusedCard" @close="closeModal()"/>
    </div>
    <WelcomeBar @eventModal="showEventModal('add')"/>
    <div class="row justify-content-center">
      <div class="justify-content-center col-sm-12 col-md-6 col-lg-4 col-container" v-for="(card, index) in cards"
        :key="index">
        <EventCard v-bind:card="card" @editCard="showEditModal"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, PropType, defineComponent } from 'vue';
import EventCard, { IEventCard } from '@/components/EventCard.vue';
import CardModal from '@/components/CardModal.vue';
import WelcomeBar from '@/components/header/WelcomeBar.vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    EventCard,
    WelcomeBar
  },

  data() {
    return {
      showModal: false as boolean,
      modalComponent: null as null | Component,
      modalArgument: null as null | string,
      focusedCard: null as null | IEventCard,
      cards: [
        {
          id: 2,
          title: 'Exercise',
          text: 'Go do some healthy stuff dude',
          goodEvent: true,
          context: [
            {
              name: 'Heavy',
              value: 4
            },
            {
              name: 'Moderate',
              value: 3
            },
            {
              name: 'Easy',
              value: 2
            }
          ]
        },
        {
          id: 1,
          title: 'Don\'t Consume',
          text: 'Don\'t consume you shouldn\'t',
          focus: true,
          maxEffectPerDay: 3, // Dont forget to ^-1 to normalise -1 and 1
          // maxEffectPerEvent: 3, // Dont forget to ^-1 to normalise -1 and 1
          context: [
            {
              name: 'Weed',
              value: -1
            },
            {
              name: 'Some nasty stuff',
              value: -2
            },
            {
              name: 'Some really bad stuff',
              value: -3
            },
            {
              name: 'With friends',
              value: 1
            },
            {
              name: 'Bored',
              value: 0
            },
            {
              name: 'Sad',
              value: 0
            },
            {
              name: 'Spent 10€',
              value: -1
            },
            {
              name: 'Spent more than 10€',
              value: -2
            },
            {
              name: 'Spent more than 50€',
              value: -3
            }
          ]
        },
        {
          id: 3,
          title: 'Study a bit',
          text: 'How about you finish your degree some day?',
          goodEvent: true,
          focus: true,
          maxEffectPerDay: 12,
          context: [
            {
              name: '5 hours',
              value: 5
            },
            {
              name: '4 hours',
              value: 4
            },
            {
              name: '3 hours',
              value: 3
            },
            {
              name: '2 hours',
              value: 2
            },
            {
              name: '1 hours',
              value: 1
            },
            {
              name: 'Visit all lessons',
              value: 2
            },
            {
              name: 'Skip whole lesson',
              value: -1
            },
            {
              name: 'Attend test',
              value: 4
            }
          ]
        },
        {
          id: 4,
          title: 'Cook for yourself',
          text: 'Stop giving so much of your money to Uber',
          goodEvent: true,
          context: [
            {
              name: 'Healthy',
              value: 2
            },
            {
              name: 'Whatever',
              value: 1
            }
          ]
        },
        {
          id: 5,
          title: 'Chores',
          text: 'Don\'t let your girlfriend be your mummy',
          goodEvent: true,
          maxEffectPerDay: 5,
          context: [
            {
              name: 'Dishwasher',
              value: 1
            },
            {
              name: 'Dishes',
              value: 1
            },
            {
              name: 'Vacuum',
              value: 2
            },
            {
              name: 'Bathroom',
              value: 3
            },
            {
              name: 'Clothes',
              value: 2
            }
          ]
        },
        {
          id: 6,
          title: 'Save some money',
          text: 'Try to save some money dude',
          goodEvent: true,
          context: [
            {
              name: '500€',
              value: 10
            },
            {
              name: '200€',
              value: 7
            },
            {
              name: '100€',
              value: 5
            },
            {
              name: '75€',
              value: 3
            },
            {
              name: '50€',
              value: 2
            }
          ]
        },
      ] as IEventCard[]
    }
  },
  methods: {
    showEventModal(action: 'edit' | 'add', res?: IEventCard) {
      this.modalArgument = action;
      if (res) {
        this.focusedCard = res;
      }
      this.modalComponent = CardModal;
      this.showModal = true;
    },
    showEditModal(res: IEventCard) {
      this.showEventModal('edit', res);
    },
    closeModal() {
      this.showModal = false;
      this.modalComponent = null;
    },
  },
});
</script>

<style scoped>
.col-container {
  max-width: 25em !important;
}
</style>