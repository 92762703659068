<template>
    <div class="row compact-scoreboard">
      <div class="col-6 text-left">
        <p class="compact-text">Your Score: <b>10</b></p>
        <p class="compact-text">Weekly Score: <b>10</b> <small>(+100% last week)</small></p>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <button :class="checkedIn ? 'btn btn-outline-success' : 'btn btn-danger'" class="mx-2 compact-button" @click="checkInAndOut()">{{ checkedIn ? 'Check out' : 'Check in' }}</button>
        <button class="btn btn-success mx-2 compact-button" @click="$emit('eventModal')" @close="closeModal()">Add Event</button>
      </div>
    </div>
  </template>
<script>
export default {
    name: 'WelcomeBar',
    data() {
        return {
            checkedIn: false
        }
    },
    methods: {
        checkInAndOut() {
            this.checkedIn = !this.checkedIn;
        }
    }
}
</script>
<style lang="scss" scoped>
.compact-scoreboard {
  align-items: center;
}

.compact-text {
  margin: 0;
  padding: 2px 0;
  line-height: 1.2;
  text-align: left; /* Ensure text is aligned left */
}

.compact-button {
  padding: 5px 10px;
  font-size: 0.875rem; /* Adjust font size to make the button more compact */
}
</style>